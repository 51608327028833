<template>
   <div class="col-12">
      <div class="card mb-0" :class="index % 2 == 0 ? '' : 'bg-secondary'">
         <div class="card-body p-2">
            <div class="row m-0 align-items-center justify-content-center">
               <div class="wpx-200 px-1 d-none d-xxl-block">
                  <span class="limitador-2 lh-base text-capitalize">
                     {{ revenda.cidade == null ? '' : revenda.cidade }} <small v-if="revenda.distancia">({{ parseFloat(revenda.distancia).toFixed(1) }} km)</small>
                  </span>
               </div>
               <div class="col-md px-1 text-center text-md-start">
                  <span class="limitador text-capitalize weight-500">
                     <i class="far fa-map-marker-alt font-11 color-theme cursor-pointer me-1" @click="abrirMapa" v-if="revenda.latitude && revenda.longitude"></i>
                     {{ revenda.nome == null ? '' : revenda.nome }}
                  </span>
                  <span class="limitador lh-1 font-10 mb-1" v-if="revenda.razaoSocial != null">{{ revenda.razaoSocial }}</span>
               </div>
               <div class="wpx-250 px-1 text-center text-md-start mx-auto mx-md-none">
                  <span class="limitador-2 lh-base text-capitalize">{{ revenda.endereco == null ? '' : revenda.endereco }}</span>
               </div>
               <div class="wpx-150 px-1 d-none d-lg-block">
                  <span class="limitador-2 lh-base">{{ revenda.telefone == null ? '' : revenda.telefone }}</span>
               </div>
               <div class="wpx-200 px-1 d-none d-xl-block">
                  <span class="limitador-2 lh-base text-lowercase">{{ revenda.email == null ? '' : revenda.email }}</span>
               </div>
               <div class="wpx-200 px-1 mt-2 mt-md-0 text-center text-md-start" v-if="especializacoes">
                  <span v-if="revenda.especializacoes.length == 0">-</span>
                  <span v-else class="limitador-2 lh-base">
                     <span v-for="(especialicao, index) in revenda.especializacoes" :key="index">
                        <img v-if="especialicao.imagem == '1'" src="@/assets/cliente/img/icon/tipo1.jpg" class="rounded me-1" :title="especialicao.descricao" />
                        <img v-else-if="especialicao.imagem == '2'" src="@/assets/cliente/img/icon/tipo2.jpg" class="rounded me-1" :title="especialicao.descricao" />
                        <img v-else-if="especialicao.imagem == '3'" src="@/assets/cliente/img/icon/tipo3.jpg" class="rounded me-1" :title="especialicao.descricao" />
                        <img v-else-if="especialicao.imagem == '4'" src="@/assets/cliente/img/icon/tipo4.jpg" class="rounded me-1" :title="especialicao.descricao" />
                        <img v-else-if="especialicao.imagem == '5'" src="@/assets/cliente/img/icon/tipo5.jpg" class="rounded me-1" :title="especialicao.descricao" />
                        <img v-else-if="especialicao.imagem == '6'" src="@/assets/cliente/img/icon/tipo6.jpg" class="rounded me-1" :title="especialicao.descricao" />
                        <img v-else-if="especialicao.imagem == '7'" src="@/assets/cliente/img/icon/tipo7.jpg" class="rounded me-1" :title="especialicao.descricao" />
                     </span>
                  </span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Revenda',
   props: ['revenda', 'index', 'especializacoes'],
   methods: {
      abrirMapa : function () {
         window.open('https://www.google.com/maps?q='+ this.revenda.latitude +','+ this.revenda.longitude)
      }
   }
}

</script>

<style scoped>

.card {
   border: 0;
   border-radius: 0;
}

</style>